<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">学校名称</label>
        <el-input
          v-model="searchSchoolName"
          placeholder="请输入学校名称"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学校代码</label>
        <el-input
          v-model="searchschoolCode"
          placeholder="请输入学校代码"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          v-if="addButton"
          type="success"
          icon="el-icon-plus"
          @click="handleAdd"
          size="small"
          >新增
        </el-button>
        <el-button
          v-if="searchButton"
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
        <el-button
          v-if="deleteButton"
          type="danger"
          icon="el-icon-delete"
          @click="handleDelete"
          size="small"
          >删除
        </el-button>
        <!-- <el-button
          v-if="addButton"
          type="success"
          icon="el-icon-export"
          @click="handleExport"
          size="small"
          >批量导入学历权限
        </el-button> -->
      </div>
    </div>

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="schoolList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        class="schools"
        @selection-change="handleSelectionChange"
      >
        <template #empty>
          <p>{{ isLoading ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column
          fixed
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column fixed prop="schoolName" label="学校名称">
        </el-table-column>
        <el-table-column prop="schoolCode" label="学校编码" align="center">
        </el-table-column>
        <el-table-column prop="schoolAddress" label="学校地址">
        </el-table-column>
        <el-table-column prop="schoolType" label="学校类别" align="center">
        </el-table-column>
        <!-- <el-table-column prop="schoolClass" label="教育类型"> </el-table-column> -->

        <!--        <el-table-column prop="orgName" label="机构名称" width="200">-->
        <!--        </el-table-column>-->
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <!-- <div
              class="my-button"
              v-if="viewButton"
              @click="handleView(scope.row.id)"
            >
              查看
            </div> -->
            <div
              class="my-button"
              v-if="updateButton"
              @click="handleUpdate(scope.row.id)"
            >
              基础信息修改
            </div>
            <!-- <div class="my-button-green" @click="handleUpdateConfig(scope.row)">
              采集授权配置
            </div> -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        style="display: flex; justify-content: center; flex-direction: row"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="
        dialogType === 1
          ? '新增学校'
          : dialogType === 2
          ? '查看学校'
          : '修改学校'
      "
      :visible.sync="dialogVisible"
      width="500px"
      class="ERER"
    >
      <el-form
        ref="schoolForm"
        :model="schoolForm"
        :rules="schoolRules"
        label-width="80px"
      >
        <el-form-item label="学校名称" prop="schoolName">
          <el-input
            type="text"
            v-model="schoolForm.schoolName"
            placeholder="请输入学校名称"
            :disabled="dialogType === 2"
          ></el-input>
        </el-form-item>
        <el-form-item label="学校编码" prop="schoolCode">
          <el-input
            type="text"
            v-model="schoolForm.schoolCode"
            placeholder="请输入学校编码"
            :disabled="dialogType != 1"
          ></el-input>
        </el-form-item>
        <el-form-item label="学校类别" prop="schoolType">
          <el-select
            v-model="schoolForm.schoolType"
            placeholder="请选择学校类别"
            :disabled="dialogType === 2"
            style="width: 100%"
          >
            <el-option
              v-for="item in schoolTypeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教育类型" prop="schoolClass">
          <el-select
            v-model="schoolForm.schoolClass"
            placeholder="请选择教育类型"
            :disabled="dialogType === 2"
            style="width: 100%"
          >
            <el-option
              v-for="item in schoolClassOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校地址" prop="schoolAddress">
          <el-input
            type="text"
            v-model="schoolForm.schoolAddress"
            placeholder="请输入学校地址"
            :disabled="dialogType === 2"
          ></el-input>
        </el-form-item>
        <!--        <el-form-item label="机构" prop="orgId">-->
        <!--          <el-select-->
        <!--            v-model="schoolForm.orgId"-->
        <!--            placeholder="请选择机构"-->
        <!--            :disabled="dialogType === 2"-->
        <!--          >-->
        <!--            <el-option-->
        <!--              v-for="item in orgOptions"-->
        <!--              :key="item.id"-->
        <!--              :label="item.orgName"-->
        <!--              :value="item.id"-->
        <!--            >-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="dialogType !== 2"
          type="primary"
          @click="handleConfirm"
          :loading="confirmLoading"
          style="margin-left: 20px"
          >保 存
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="采集授权配置"
      :visible.sync="showConfig"
      width="550px"
      class="ERER"
    >
      <el-form ref="schoolForm" label-width="80px">
        <el-form-item label="学校名称" prop="tempSchoolName">
          <el-input
            type="text"
            v-model="tempSchoolName"
            placeholder="请输入学校名称"
            :disabled="true"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="授权时间" prop="authTime">
          <div v-for="(it, idx) in authTimeList" :key="idx">
            <el-select
              v-model="it.eduType"
              placeholder="学历类型"
              size="small"
              style="width: 120px; margin-right: 10px"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in eduTypeList"
                :key="item.id"
              ></el-option>
            </el-select>
            <el-date-picker
              v-model="it.stime"
              type="date"
              placeholder="开始日期"
              size="small"
              style="width: 120px"
            >
            </el-date-picker>
            -
            <el-date-picker
              v-model="it.etime"
              type="date"
              placeholder="结束日期"
              size="small"
              style="width: 120px"
            >
            </el-date-picker>
            <span v-if="idx == 0" style="margin-left: 5px" @click="addAuth()"
              >添加</span
            >
            <span v-if="idx" style="margin-left: 5px" @click="delAuth(idx)"
              >删除</span
            >
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showConfig = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleOk"
          :loading="confirmLoadingOk"
          style="margin-left: 20px"
          >保 存
        </el-button>
      </div>
    </el-dialog>

    <!-- 上传学生信息 -->
    <el-dialog
      title="批量导入学历权限"
      :visible.sync="eduUplodShow"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-upload
        class="upload-demo"
        ref="uploadImport"
        :multiple="false"
        action=""
        :limit="1"
        :on-change="handleUploadChange"
        :file-list="[]"
        accept=".xls,.xlsx"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
          :loading="eduUpdateLoading"
          :disabled="eduUpdateLoading"
          >{{ eduUpdateLoading ? "数据批量导入中..." : "选取文件" }}
        </el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传xls/xlsx文件，且不超过10M
          <a :href="'/temp/schoolEdu.xls?ver=' + times">下载模板</a>
        </div>
      </el-upload>
    </el-dialog>
    <!-- 上传学生信息 -->
  </div>
</template>

<script>
import {
  querySchool,
  addSchool,
  getSchool,
  updateSchool,
  deleteSchool,
  checkSchoolEduType,
  postSchoolEduType,
  importEduTypeAsyn,
} from "@/api/school";
import { exportprocess } from "@/api/student";
import { queryOrgDictByOrgType } from "@/api/org";
import { getDict } from "@/api/dict";
import { queryPageButton } from "@/api/permission";

export default {
  name: "SchoolInfoMgt",
  data() {
    const validateSchoolName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校名称不能为空"));
      } else {
        callback();
      }
    };
    const validateSchoolCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校代码不能为空"));
      } else {
        callback();
      }
    };
    const validateSchoolType = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校类别不能为空"));
      } else {
        callback();
      }
    };
    const validateSchoolClass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("教育类型不能为空"));
      } else {
        callback();
      }
    };
    const validateSchoolAddress = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校地址不能为空"));
      } else {
        callback();
      }
    };
    // const validateOrgId = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("机构不能为空"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      times: Date.now() * 1,
      eduUplodShow: false,
      eduUpdateLoading: false,
      searchSchoolName: "",
      searchschoolCode: "",
      schoolList: [],
      tableLoading: false,
      confirmLoadingOk: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      isLoading: true,
      dialogType: 1,
      dialogVisible: false,
      schoolForm: {},
      eduTypeList: [
        { id: 1, name: "普通本科" },
        { id: 2, name: "普通专科" },
        { id: 3, name: "成人本科" },
        { id: 4, name: "成人专科" },
        { id: 5, name: "研究生" },
      ],
      authTimeList: [{ eduType: null, stime: "dasdsa", etime: "" }],
      schoolRules: {
        schoolName: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolName,
          },
        ],
        schoolCode: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolCode,
          },
        ],
        schoolType: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolType,
          },
        ],
        schoolClass: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolClass,
          },
        ],
        schoolAddress: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolAddress,
          },
        ],
        // orgId: [{ required: true, trigger: "blur", validator: validateOrgId }],
      },
      schoolTypeOptions: [],
      schoolClassOptions: [],
      orgOptions: [],
      confirmLoading: false,
      //按钮权限
      searchButton: false,
      addButton: false,
      updateButton: false,
      deleteButton: false,
      viewButton: false,
      showConfig: false,
      tempSchoolId: null,
      tempSchoolName: null,
    };
  },
  created() {
    this.queryButton();
    this.query();
    this.querySchoolType();
    this.querySchoolClass();
  },
  methods: {
    queryButton() {
      const data = 6;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "add") {
            this.addButton = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
        }
      });
    },
    query() {
      this.tableLoading = true;
      const data = {
        schoolName: this.searchSchoolName,
        schoolCode: this.searchschoolCode,
      };
      this.isLoading = true;
      querySchool(this.currentPage, this.pageSize, data).then((resp) => {
        const content = resp.data.content;
        for (const item of content) {
          for (const typeItem of this.schoolTypeOptions) {
            if (item.schoolType === typeItem.code) {
              item.schoolType = typeItem.name;
            }
          }
          for (const classItem of this.schoolClassOptions) {
            if (item.schoolClass === classItem.code) {
              item.schoolClass = classItem.name;
            }
          }
        }
        this.isLoading = false;
        this.schoolList = content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    queryOrg() {
      queryOrgDictByOrgType(0).then((resp) => {
        this.orgOptions = resp.data;
      });
    },
    querySchoolType() {
      getDict("school_type").then((resp) => {
        this.schoolTypeOptions = resp.data;
      });
    },
    querySchoolClass() {
      getDict("school_class").then((resp) => {
        this.schoolClassOptions = resp.data;
      });
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchSchoolName = "";
      (this.searchschoolCode = ""), (this.currentPage = 1);
      this.query();
    },
    handleAdd() {
      this.queryOrg();
      this.schoolForm = {};
      if (this.$refs.schoolForm !== undefined) {
        this.$refs.schoolForm.resetFields();
      }
      this.dialogType = 1;
      this.dialogVisible = true;
    },
    handleConfirm() {
      if (this.dialogType === 1) {
        this.add();
      } else if (this.dialogType === 3) {
        this.update();
      }
    },
    add() {
      this.$refs.schoolForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.schoolForm;

          addSchool(data)
            .then((resp) => {
              this.$notify({
                title: "新增成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleExport() {
      this.eduUplodShow = true;
    },
    /**
     * 上传学生信息
     */
    handleUploadChange(file, fileList) {
      let fdParams = new FormData();
      fdParams.append("file", file.raw);
      this.eduUpdateLoading = true;
      importEduTypeAsyn(fdParams).then((res) => {
        if (res.data.code == 0) {
          let json = JSON.parse(res.data.data);
          this.taskExportprocess(json.taskNo);
        } else {
          this.$notify({
            title: "警告",
            message: res.message,
            type: "warning",
          });
          this.eduUpdateLoading = false;
          return;
        }
      });
    },
    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo = "") {
      let that = this;
      exportprocess(taskNo).then((res) => {
        if (res.data.state == 0) {
          setTimeout(() => {
            that.taskExportprocess(taskNo);
          }, 500);
        } else if (res.data.state == 1) {
          that.$notify({
            title: "成功",
            message: "批量导入学历信息成功",
            type: "success",
          });
          that.eduUpdateLoading = false;
          that.eduUplodShow = false;
          this.queryLIst();
        } else {
          this.$notify({
            title: "警告",
            message: "批量导入学历信息失败，请重新上传",
            type: "warning",
          });
          that.eduUpdateLoading = false;
          this.queryLIst();
        }
      });
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的学校不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该学校, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }

          deleteSchool(data)
            .then((resp) => {
              if (resp.code === "0") {
                this.$notify({
                  title: "删除成功",
                  message: resp.message,
                  type: "success",
                });
                this.currentPage = 1;
                this.query();
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.message,
                  type: "warning",
                });
              }
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleView(id) {
      const data = id;
      getSchool(data)
        .then((resp) => {
          this.queryOrg();
          if (this.$refs.schoolForm !== undefined) {
            this.$refs.schoolForm.resetFields();
          }
          this.schoolForm = resp.data;

          this.dialogType = 2;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdateConfig(datas) {
      this.tempSchoolId = datas.id;
      this.tempSchoolName = datas.schoolName;
      checkSchoolEduType(datas.id).then((res) => {
        if (res.code == 0 && res.data.length) {
          this.authTimeList = res.data;
        } else {
          this.authTimeList = [
            {
              schoolId: datas.id,
              eduType: null,
              stime: "",
              etime: "",
            },
          ];
        }
      });

      this.showConfig = true;
    },
    handleUpdate(id) {
      const data = id;
      getSchool(data)
        .then((resp) => {
          this.queryOrg();
          if (this.$refs.schoolForm !== undefined) {
            this.$refs.schoolForm.resetFields();
          }
          this.schoolForm = resp.data;

          this.dialogType = 3;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    update() {
      this.$refs.schoolForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.schoolForm;

          updateSchool(data)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    addAuth() {
      this.authTimeList.push({
        schoolId: this.tempSchoolId,
        eduType: null,
        stime: "",
        etime: "",
      });
    },
    delAuth(idx) {
      this.authTimeList.splice(idx, 1);
    },
    handleOk() {
      const json = JSON.parse(JSON.stringify(this.authTimeList));
      // console.log(json);
      for (const [index, item] of json.entries()) {
        if (!item.eduType) {
          this.$notify({
            title: "警告",
            message: `第${index + 1}行，请选择学历类型`,
            type: "warning",
          });
          return false;
        }
        if (!item.stime) {
          this.$notify({
            title: "警告",
            message: `第${index + 1}行，请输入开始日期`,
            type: "warning",
          });
          return false;
        }
        if (!item.etime) {
          this.$notify({
            title: "警告",
            message: `第${index + 1}行，请输入结束日期`,
            type: "warning",
          });
          return false;
        }
        if (item.stime > item.etime) {
          this.$notify({
            title: "警告",
            message: `第${index + 1}行，开始日期不能大于结束日期`,
            type: "warning",
          });
          return false;
        }
      }
      postSchoolEduType(json).then((res) => {
        if (res.code == 0) {
          this.$notify({
            title: "修改成功",
            message: res.message,
            type: "success",
          });
          this.showConfig = false;
        } else {
          this.$notify({
            title: "警告",
            message: res.message,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style>
.schools .el-checkbox__inner {
  border-radius: 20px !important;
}

.schools .el-button--primary {
  color: #446cf3;
  background-color: #ffffff;
  border-color: #446cf3;
}

.schools .el-button--success {
  border-color: #0bc58d;
  color: #0bc58d !important;
}

.schools .el-button--success {
  color: #fff;
  background-color: #ffffff;
  border-color: #0bc58d;
}
</style>
